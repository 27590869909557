// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-co-nowego-js": () => import("./../src/pages/co_nowego.js" /* webpackChunkName: "component---src-pages-co-nowego-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kolekcja-js": () => import("./../src/pages/kolekcja.js" /* webpackChunkName: "component---src-pages-kolekcja-js" */),
  "component---src-pages-o-nas-js": () => import("./../src/pages/o_nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-personalizacja-js": () => import("./../src/pages/personalizacja.js" /* webpackChunkName: "component---src-pages-personalizacja-js" */),
  "component---src-pages-tkaniny-js": () => import("./../src/pages/tkaniny.js" /* webpackChunkName: "component---src-pages-tkaniny-js" */),
  "component---src-templates-info-js": () => import("./../src/templates/info.js" /* webpackChunkName: "component---src-templates-info-js" */),
  "component---src-templates-produkt-js": () => import("./../src/templates/produkt.js" /* webpackChunkName: "component---src-templates-produkt-js" */)
}

